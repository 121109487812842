var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "social-player-card", attrs: { column: "" } },
    [
      _c(
        "div",
        { staticClass: "social-player-card__player" },
        [
          _c("RingStarsAnimation", { attrs: { animate: _vm.isWinner } }),
          _vm.isActualPriceIsRightWinner && _vm.priceIsOver
            ? _c(
                "Medal",
                {
                  staticClass: "social-player-card__medal",
                  attrs: { variant: "1" },
                },
                [_vm._v(" WINNER ")]
              )
            : _vm._e(),
          _c(
            "AppUserCard",
            _vm._g(
              {
                staticClass: "social-player-card__body",
                attrs: {
                  user: _vm.user,
                  mutable: _vm.isHost,
                  dominantable: _vm.isMobileSpeaker,
                },
                scopedSlots: _vm._u(
                  [
                    _vm.isHost && _vm.isMobileSpeakerBtnVisible
                      ? {
                          key: "actions",
                          fn: function () {
                            return [
                              _c(
                                "UserCardButton",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      if (
                                        $event.ctrlKey ||
                                        $event.shiftKey ||
                                        $event.altKey ||
                                        $event.metaKey
                                      ) {
                                        return null
                                      }
                                      return _vm.toggleMobileSpeakerID(_vm.user)
                                    },
                                  },
                                },
                                [_c("HumanGreeting")],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "namePostfix",
                      fn: function () {
                        return [
                          _vm.isHost && _vm.showScore
                            ? _c(
                                "span",
                                { staticClass: "social-player-card__score" },
                                [_vm._v(" " + _vm._s(_vm.num) + " PTS ")]
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              _vm.$listeners
            ),
            [
              _vm.teamIcon
                ? _c(
                    "v-icon",
                    {
                      staticClass: "social-player-card__team-icon",
                      class: _vm.iconClass,
                      attrs: { dark: "" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.user.icon || "radio_button_unchecked") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isStartRatingAvailable && !_vm.readOnly && !_vm.isPriceIsRight
                ? _c("SocialStars", {
                    staticClass: "social-player-card__stars",
                    on: {
                      onStarred: function ($event) {
                        return _vm.setRating($event)
                      },
                    },
                  })
                : _vm._e(),
              _vm.isHandMovement && _vm.user.id === _vm.viewer.id
                ? _c("HandMovementVideo", { attrs: { userID: _vm.user.id } })
                : _vm._e(),
              _vm.answerTitle
                ? _c(
                    "div",
                    {
                      staticClass: "social-player-card__text-overlay",
                      on: {
                        click: function ($event) {
                          _vm.isHost && _vm.$emit("changeTask")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.answerTitle) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("BuzzInAction", {
        staticClass: "social-player-card__buzzer",
        attrs: { disabled: !_vm.isCurrentUser || !!_vm.buzzCount },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var onBuzzIn = ref.onBuzzIn
              return [
                _vm.isBuzzable
                  ? _c("BuzzInBtn", {
                      attrs: {
                        buzzCount: _vm.buzzCount,
                        isWaiting: _vm.buzzCount === 0 && !_vm.isCurrentUser,
                        isActive: _vm.buzzCount === 0 && _vm.isCurrentUser,
                      },
                      on: { click: onBuzzIn },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.isPriceIsRight
        ? _c(
            "div",
            { staticClass: "price-controls" },
            [
              _vm.priceIsActive
                ? _c("v-text-field", {
                    staticClass: "price-field",
                    attrs: { "align-right": "", type: "number", step: ".01" },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.priceSubmit()
                      },
                    },
                    model: {
                      value: _vm.startingPrice,
                      callback: function ($$v) {
                        _vm.startingPrice = $$v
                      },
                      expression: "startingPrice",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "price-btn",
                  class: {
                    "price-btn--active": _vm.priceIsActive,
                    "price-btn--entered":
                      _vm.priceIsRight && !_vm.priceIsActive,
                    "price-btn--winner":
                      _vm.isActualPriceIsRightWinner && _vm.priceIsOver,
                    "price-btn--not-winner":
                      !_vm.isActualPriceIsRightWinner && _vm.priceIsOver,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.priceSubmit()
                    },
                  },
                },
                [
                  _vm.priceIsActive && !_vm.priceIsRight
                    ? _c("span", [_vm._v(" Submit ")])
                    : _c("span", [_vm._v(" " + _vm._s(_vm.priceText) + " ")]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }