














import Vue from "vue"
import StarRating from "vue-star-rating"
export default Vue.extend({
  name: "SocialStars",
  components: { StarRating }
})
