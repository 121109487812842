import Vue from "vue"

export default Vue.extend({
  name: "HumanGreeting",
  functional: true,
  render(h, { data }) {
    return h(
      "svg",
      { attrs: { viewBox: "0 0 24 24", fill: "currentColor" }, ...data },
      [
        h("path", {
          attrs: {
            d: "M12 2C13.1 2 14 2.9 14 4S13.1 6 12 6 10 5.1 10 4 10.9 2 12 2M15.9 8.1C15.5 7.7 14.8 7 13.5 7H11C8.2 7 6 4.8 6 2H4C4 5.2 6.1 7.8 9 8.7V22H11V16H13V22H15V10.1L19 14L20.4 12.6L15.9 8.1Z"
          }
        })
      ]
    )
  }
})
