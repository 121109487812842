







































































































import { computed, defineComponent, toRefs } from "@vue/composition-api"
import { mapGetters } from "vuex"
import { Power3, TweenMax } from "gsap/TweenMax"

import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import HumanGreeting from "@/components/UserCard/icons/HumanGreeting"
import RingStarsAnimation from "@/components/GroupTeams/Common/Player/Parts/RingStarsAnimation.vue"
import SocialStars from "@/components/GroupTeams/Common/Player/Parts/SocialStars.vue"
import Medal from "@/components/GroupTeams/Common/Medal.vue"
import Mission from "@shared/Mission"

import MissionType from "@shared/enums/Mission"
import UserCardButton from "@/components/UserCard/UserCardButton"
import BuzzInBtn from "@/components/GroupTeams/Common/Games/BuzzIn/BuzzInBtn.vue"
import BuzzInAction from "@/components/GroupTeams/Common/Games/BuzzIn/BuzzInAction"

import User from "@shared/User"

import useStore from "@/use/useStore"
import UserSoundService from "@/services/user-sound.service"

export default defineComponent({
  name: "SocialPlayerCard",
  components: {
    BuzzInBtn,
    BuzzInAction,
    HumanGreeting,
    SocialStars,
    UserCardButton,
    RingStarsAnimation,
    Medal,
    AppUserCard,
    HandMovementVideo: () =>
      import(
        "@/components/GroupTeams/Common/Games/HandMovement/HandMovementVideoFeed.vue"
      )
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    allowKeys: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    teamIcon: {
      type: Boolean,
      default: true
    },
    showScore: {
      type: Boolean,
      default: true
    },
    buzzCount: Number,
    priceCount: Number,
    priceIsOver: Boolean
  },
  setup(props) {
    const { user } = toRefs(props)
    const { store } = useStore()

    const isHost = computed(() => store.getters["auth/isHost"])

    const gameMode = computed(() => store.getters.getCurrentMode)

    const viewer = computed(() => store.getters.user)

    const isCurrentUser = computed(() => viewer.value.id === user.value.id)

    const priceIsRight = computed(() => user.value.priceIsRight)

    return {
      priceIsRight,
      gameMode,
      viewer,
      isHost,
      isCurrentUser
    }
  },
  data() {
    return {
      startingPrice: null,
      num: 0,
      rating: 0
    }
  },
  computed: {
    ...mapGetters("group", ["isUserIRLSpeaker"]),
    isMobileUser() {
      return User.isMobile(this.user)
    },
    isMobileSpeaker() {
      return this.isUserIRLSpeaker(this.user)
    },
    isMobileSpeakerBtnVisible() {
      return this.isMobileSpeaker || this.isMobileUser
    },
    answerTitle() {
      return this.user?.answer?.title
    },
    isWinner() {
      if (this.currentMission?.dontShowCorrect === true) return false
      return this.user?.winner
    },
    missionType() {
      return this.currentMission?.behavior
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    iconClass() {
      return {
        "social-player-card__team-icon--high":
          this.missionType === MissionType.Charades && this.isWinner
      }
    },
    isPriceIsRight() {
      return this.missionType === MissionType.PriceIsRight
    },
    isHandMovement() {
      return this.missionType === MissionType.HandMovement
    },
    isStartRatingAvailable() {
      return (
        this.isHost &&
        ![MissionType.Charades, MissionType.Award].includes(this.missionType)
      )
    },
    isActualPriceIsRightWinner() {
      return this.user.priceWinner
    },
    isBuzzable() {
      return Mission.isBuzzable(this.currentMission)
    },
    score() {
      return this.user.score
    },
    missionPlaysArray() {
      return this.$store.getters.missionPlaysArray
    },
    isCompleted() {
      if (!this.isPriceIsRight) return false
      return this.missionPlaysArray.some(play => play.userID == this.userID)
    },
    priceIsActive() {
      return (
        (this.isCurrentTurn &&
          this.isCurrentUser &&
          !this.priceIsRight &&
          !this.priceIsOver) ||
        this.isEditableForHost
      )
    },
    isCurrentTurn() {
      return this.priceCount >= this.user.index && !this.isCompleted
    },
    isEditableForHost() {
      return this.isHost && this.isCurrentTurn && !this.priceIsOver
    },
    priceText() {
      if (this.priceIsRight) return this.priceIsRight
      if (this.priceIsActive) return "Submit"
      if (this.user.index === 1) return "1ST UP"
      if (this.user.index === 2) return "2ND UP"
      if (this.user.index === 3) return "3RD UP"
      return this.user.index + "TH"
    }
  },

  created() {
    if (this.allowKeys && !this.isPriceIsRight) {
      window.addEventListener("keyup", this.onKeyUp)
    }
  },

  beforeDestroy() {
    if (this.allowKeys && !this.isPriceIsRight) {
      window.removeEventListener("keyup", this.onKeyUp)
    }
  },

  watch: {
    score: {
      handler(newValue, oldValue) {
        if (!newValue) {
          this.rating = 0
        }
        if (isNaN(newValue)) return 0
        const obj = { num: oldValue }
        TweenMax.to(obj, 1, {
          num: newValue,
          ease: Power3.easeOut,
          roundProps: "num",
          onUpdate: this.setScore,
          onUpdateParams: [obj]
        })
      },
      immediate: true
    }
  },

  methods: {
    toggleMobileSpeakerID(user) {
      if (this.isUserIRLSpeaker(user)) {
        UserSoundService.revokeSpeaker(user)
      } else {
        UserSoundService.makeSpeaker(user)
      }
    },
    onKeyUp({ code, target }) {
      if (
        ["text", "textarea", "search", "number"].includes(target?.type) &&
        ![null, undefined, ""].includes(target?.value)
      ) {
        return
      }

      switch (code) {
        case "KeyA":
          return this.setRating(1)
        case "KeyS":
          return this.setRating(2)
        case "KeyD":
          return this.setRating(3)
        case "KeyF":
          return this.setRating(4)
        case "KeyG":
          return this.setRating(5)
        default:
          return 0
      }
    },
    setRating(num) {
      this.rating = num
      this.$emit("onStarred", num)
    },
    setScore({ num }) {
      this.num = num
    },
    priceSubmit() {
      const price = parseFloat(this.startingPrice)
      if (!price) {
        alert("You must submit a number")
        this.startingPrice = null
        return
      }
      this.$emit("priceSubmit", price)

      this.$store.dispatch("updateGameStatusAny", {
        endTime: this.currentMission.time * 1000 + Date.now()
      })
    }
  }
})
