var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("StarRating", {
    attrs: {
      "active-color": "#fff",
      "border-color": "#fff",
      "inactive-color": "",
      increment: 0.5,
      padding: 3,
      "star-size": 15,
      "show-rating": false,
      "border-width": 2,
    },
    on: {
      "rating-selected": function ($event) {
        return _vm.$emit("onStarred", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }